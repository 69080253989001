/* :root{
  --themeclr:#f6df2d
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
:root{
  --themeclr:  #D2E828;
  --textclr:#7547F1;
  --black: #161515;
  --grey: #333333;
}

body{
  background-color: var(--black) !important;
  /* background-image: url(./Assets/images/bg.png); */
  color:#fff;
  font-family: medium;
}
@font-face {
  font-family: themefont;
  src: url(./Assets/Font/Gilroy-Black.ttf);
}
@font-face {
  font-family: thin;
  src: url(./Assets/Font/Gilroy-Thin.ttf);
}
@font-face {
  font-family: bold;
  src: url(./Assets/Font/Gilroy-Bold.ttf);
}
@font-face {
  font-family: extrabold;
  src: url(./Assets/Font/Gilroy-ExtraBold.ttf);
}
@font-face {
  font-family: medium;
  src: url(./Assets/Font/Gilroy-Medium.ttf);
}
@font-face {
  font-family: normal;
  src: url(./Assets/Font/Gilroy-Regular.ttf);
}
@font-face {
  font-family: light;
  src: url(./Assets/Font/Gilroy-Light.ttf);
}
table{
  color: #fff !important;
}
.list-group{
  background-color: var(--black);
}
.list-group a,.list-group-item{
  color: #fff !important;
  background-color: var(--black) !important;
}
.list-group-item.active{
  border-color: var(--themeclr) !important;
}
.list-group-item-action:hover, .list-group-item-action:focus,.list-group-item-action.active{
  background-color: var(--themeclr) !important;
}
.list-group-item-action:hover , .list-group-item-action:focus,.list-group-item-action.active {
  color: var(--black) !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

header, main, footer {
  padding-left: 300px;
}

@media only screen and (max-width : 992px) {
  header, main, footer {
    padding-left: 0;
  }
}
.jc-between{
  justify-content: space-between !important;
}

/* NEW CSS */
/* .logo{
  height: 45px;
} */
.border-right {
  border-right: 1px solid var(--grey) !important;
}
.asrt-table-foot {
  color: #fff;
}
.navbar.bg-primary
{
  background: var(--black) !important;
  border-bottom: 1px solid var(--grey);
}
#page-content-wrapper .text-primary {
  color: var(--themeclr) !important;
}

.btn.btn-outline-primary,.btn-theme{
  /* background-image: linear-gradient(#7aff58, #a6f232, #e3fc0f) !important;
  color: black !important; */
  background: var(--textclr) !important;
    font-family: 'extrabold';
    border-radius: 0;
    position: relative;
    min-width: 120px;
    padding: 10px 20px;
    color: #000;
}
.btn.btn-outline-primary:hover,.btn-theme:hover {
  background: var(--themeclr);
  color: #000;
}
.btn.btn-outline-primary:after,.btn-theme:after{
  content: "";
    width: 100%;
    height: 100%;
    border: 1px solid white;
    position: absolute;
    left: -5px;
    bottom: 4px;
    transition: 0.2s;
}
.loginsec{
  background-color: #1A1A1A !important;
  border: 1px solid var(--themeclr) !important;
}
.loginsec label {
  color: #fff;
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link,.navbar-dark .navbar-brand{
  color:#fff !important;
}
.btn-link
{
  color: var(--themeclr)   !important;
}
.input-group span {
  background: black !important;
  color: #fff;
  border-radius: 0;
}
.pagination li a {
  background: #000 !important;
}
th{
color: var(--themeclr) !important;
}


.card_dark_user.bg-secondary,.card.bg-primary{
   background-color: #0f0f0f !important;
   color: #fff !important;
   border: 1px solid var(--grey) !important;

}
.btn-primary
{
/* background-color: #a1cb00   !important; */
background: var(--themeclr) !important;
border-color: var(--themeclr)   !important;
color: var(--black) !important

}
.btn-primary:hover{
  background: var(--textclr) !important;
  border-color: var(--textclr)   !important;
  color: #fff !important;
}


.responsive-table .col-md-12{
    /* width: 100%; */
    overflow-x: auto;
    /* max-width: 100%; */
}
.responsive-table {
  margin-top: 33px;
  overflow: hidden;
}
.hut-cont label {
  color: #fff;
  margin-top: 10px;
}

.input-group span {
  background: #2e2e2b !important;
  color: #fff;
  border-radius: 0 4px 4px 0;
  border-left: 0;
}

.custom-file-label {
  left: 14px !important;
  width: 95%;
}
.ml-auto
{
  margin-left:auto !important;
}

.float-right {
  float: right !important;
}

@media only screen and (min-width:992px)
{
  .right_sec_align
  {
    display: flex;
    justify-content: flex-end;
  }

}
@media(max-width:991px){
  #sidebar-wrapper .list-group {
    width: 11rem !important;
}
}

.btn-light{
 
  background: var(--themeclr) !important;
  font-size: 16px;
  padding: 5px 15px;
  font-weight: 500;
  border: 1px solid var(--themeclr) !important;
  color: var(--black) !important;
}


.btn-light:hover{
  color: #fff !important ;
  background: transparent !important;
  border: 1px solid var(--themeclr) !important;
}
.btn.btn-outline-primary, .btn-theme{
 
  font-size: 16px;
  color: #fff;
  border-radius: 7px !important;  
  background: var(--themeclr) !important;
  border: 1px solid var(--themeclr) !important;
  color: var(--black) !important;
}
.btn.btn-outline-primary:after, .btn-theme:after{
display: none;

}
.btn.btn-outline-primary:hover, .btn-theme:hover{

  color: #fff !important ;
  background: transparent !important;
  border: 1px solid var(--themeclr) !important;
}
.list-group-item.active {

  color: var(--black) !important;
}
.form-control:focus{
  box-shadow: none !important;
  border-color: none !important;
}
input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px #2e2e2e inset !important; /* Change the color to your own background color */
  -webkit-text-fill-color: #fff;

}
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #2e2e2e inset !important;/*your box-shadow*/
  -webkit-text-fill-color: #fff;

} 
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 50px #2e2e2e inset !important;/*your box-shadow*/
  -webkit-text-fill-color: #fff;

} 
.btn:focus{
  box-shadow: none !important;
}
.btn-danger{
  background: var(--textclr) !important;
  border: var(--textclr) !important;

}
.btn-danger:hover{
  background: var(--themeclr) !important;
  border: var(--themeclr) !important;
  color: var(--black) !important;

}
#update-form-modal .uploads_img{
  min-height: 150px;
  max-width: 100%;
  max-height: 150px;

  border-radius: 10px;
  margin: auto;
  margin-top: 30px !important;
  margin-bottom: 30px;
}
 input[type="file"]{
  padding: 3px;
}
.table_filter{
  margin-bottom: 10px;
}
.hut-body button{
  color: var(--black) !important;
}
.hut-body .btn-theme{
  color: var(--black);
}


.btn-light {
  display: flex !important;
  align-items: center;
  gap: 8px;
  width: max-content;
}

#page-content-wrapper .card.bg-secondary svg, #page-content-wrapper .card.bg-primary svg {
  color: var(--textclr) !important;
}

.ql-editor p, .ql-editor p span {
  color: #fff !important;
}

.table td {
  color: #fff;
}

.table th, .table td {
  vertical-align: middle !important;
}

.table td img {
  display: block;
  margin: auto;
}

.modal-header {
  border-bottom: 1px solid var(--grey) !important;
}
.modal-footer {
  border-top: 1px solid var(--grey) !important;
}

.Toastify__progress-bar--default {
  background: var(--themeclr) !important;
}

